import React, { FunctionComponent } from "react"
import Nav from "react-bootstrap/Nav"
import Navbar from "react-bootstrap/Navbar"
import Container from "react-bootstrap/Container"
import runloopLogo from "../images/runloop-logo.svg"
import { graphql, useStaticQuery } from "gatsby"
import EmptyProps from "./empty-props"

const Header: FunctionComponent<EmptyProps> = () => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            urls {
              support
            }
          }
        }
      }
    `
  )
  return (
    <Container>
      <Navbar expand="lg">
        <Navbar.Brand href="/">
          <img src={runloopLogo} alt="Runloop" width={103} height={17} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link href="/seconds-interval-timer">
              Seconds Interval Timer
            </Nav.Link>
            <Nav.Link href="/heavyset">HeavySet</Nav.Link>
            <Nav.Link href={data.site.siteMetadata.urls.support}>
              Support
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Container>
  )
}

export default Header
