import React, { FunctionComponent } from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { graphql, Link, useStaticQuery } from "gatsby"
import Rule from "./rule"
import EmptyProps from "./empty-props"
import { LinkList } from "../helpers/style"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const Footer: FunctionComponent<EmptyProps> = () => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            urls {
              support
            }
          }
        }
      }
    `
  )
  return (
    <div>
      <Container className="py-5">
        <Row>
          <Col xs={12} sm={3}>
            <h5>Products</h5>
            <LinkList>
              <li>
                <Link to="/seconds-interval-timer">Seconds Interval Timer</Link>
              </li>
              <li>
                <Link to="/heavyset">HeavySet - Gym Log</Link>
              </li>
            </LinkList>
          </Col>
          <Col xs={12} sm={3}>
            <h5>Support</h5>
            <LinkList>
              <li>
                <a href={data.site.siteMetadata.urls.support}>Help Center</a>
              </li>
              <li>
                <OutboundLink href="https://www.youtube.com/playlist?list=PL5PrGVx5TpT9Luw6ovCl_h-rnGc8bf-6o">
                  Seconds Tutorial Videos
                </OutboundLink>
              </li>
              <li>
                <OutboundLink href="https://www.youtube.com/watch?v=mDuXnVD9MEc&list=PL5PrGVx5TpT-k272_T6xGeSxBJxtffhJV">
                  HeavySet Tutorial Videos
                </OutboundLink>
              </li>
            </LinkList>
          </Col>
          <Col xs={12} sm={3}>
            <h5>Social</h5>
            <LinkList>
              <li>
                <OutboundLink href="https://www.youtube.com/c/runloop">
                  YouTube
                </OutboundLink>
              </li>
              <li>
                <OutboundLink href="https://www.instagram.com/runloop">
                  Instagram
                </OutboundLink>
              </li>
              <li>
                <OutboundLink href="https://twitter.com/runloop">
                  Twitter
                </OutboundLink>
              </li>
            </LinkList>
          </Col>
          <Col xs={12} sm={3}>
            <h5>About</h5>
            <LinkList>
              <li>
                <Link to="/seconds-interval-timer/press-kit">
                  Seconds Press Kit
                </Link>
              </li>
            </LinkList>
            <h5>Legal</h5>
            <LinkList>
              <li>
                <Link to="/legal/privacy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/legal/terms">Terms and Conditions</Link>
              </li>
              <li>
                <Link to="/legal/cookie-policy">Cookie Policy</Link>
              </li>
            </LinkList>
          </Col>
        </Row>
      </Container>
      <Rule />
      <Container className="py-3">
        <Row>
          <Col>© {new Date().getFullYear()} Runloop Ltd.</Col>
        </Row>
      </Container>
    </div>
  )
}

export default Footer
