import Row from "react-bootstrap/Row"
import Container from "react-bootstrap/Container"
import React from "react"
import EmptyProps from "./empty-props"

const Rule: React.FunctionComponent<EmptyProps> = props => {
  return (
    <Container>
      <Row
        style={{
          borderTop: "solid 1px #eceeef",
        }}
      />
    </Container>
  )
}

export default Rule
